import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import axios from "axios";
import SEO from "../../seo";
import swal from "sweetalert2";

import 'sweetalert2/src/sweetalert2.scss'


export default function CareersPage() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    number: "",
    institution: "",
    position: "",
    functionalArea: null,
    resume: null,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const vacanciesData = [
    {
      vacancyType: "Administrative Vacancies",
      positions: ["Vice Principal"],
      criteria: `The Candidate must
        be a Post Graduate with B.Ed/ M.Ed having
        10 years of experience as PGT & at least 3
        years experience at administrative level in
        a reputed CBSE School. 
        The candidate must have a proven track
        record of success to drive curriculum
        development, innovative pedagogical
        practices, holistic education, staff
        enrichment, team building and leadership
        qualities with proficiency in computers,
        effective communication skills and
        knowledge of NEP.`,
    },

    {
      vacancyType: "Administrative Vacancies",
      positions: ["Primary Headmistress"],
      criteria:
        "The Candidate must be a Post Graduate with 10 years of teaching experience including 3 years experience in Administration in a reputed CBSE School. For the above post the candidate must have a proven track record of success to drive curriculum development, innovative pedagogical practices, holistic education, staff enrichment, team building and leadership qualities with proficiency in computers, effective communication skills and knowledge of NEP.",
    },

    {
      vacancyType: "Administrative Vacancies",
      positions: ["Admin Executive School Operations and Finance "],
      criteria:
        "M.Com/ MBA with good communication skills, spoken English, computer proficiency and minimum 5 years of experience in Delhi Schools. The candidate should be well-versed with school administration and have good knowledge of Delhi School Education Rules and Act.",
    },

    {
      vacancyType: "Administrative Vacancies",
      positions: ["PA to Principal"],
      criteria:
        "Should be a graduate with a minimum of 5 years of experience in a similar position, with strong communication and computer skills. ",
    },

    {
      vacancyType: "Teaching Posts",
      positions: ["TGT (S.St, Math & Hindi)", "PRT (Computer Science, Maths & all subjects)"],
      criteria:
        "Minimum 3 years of experience required for all posts. Qualifications as per CBSE norms and CTET requirement. ",
    },

    {
      vacancyType: "Other Posts",
      positions: ["IT Support Engineer / (IT Coordinator)"],
      criteria: `Should be B.Tech (IT)
        / MCA minimum 3 years’ experience,
        with good knowledge of multimedia,
        web technology and networking.`,
    },
  ];


  const options = [
    { value: "Academic", label: "Academic" },
    { value: "Administration", label: "Administration" },
    { value: "Guidance & Counselling ", label: "Guidance & Counselling " },
    { value: "Performing Arts ", label: "Performing Arts " },
    { value: "Physical Education ", label: "Physical Education " },
    { value: "Careers", label: "Careers" },
    { value: "Contact Information", label: "Contact Information" },
    { value: "Other", label: "Other" },
  ];

  function handleInputChange(e) {
    if (e.target.name !== "resume") {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, resume: e.target.files[0] });
    }
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    const supportedFormats = ["docx", "pdf", "doc"];
    const {
      name,
      email,
      number,
      institution,
      position,
      functionalArea,
      resume,
    } = values;

    console.log(values);
    const extention = resume.name.split(".")[resume.name.split(".").length - 1];
    console.log(extention);
    let isCorrectDoc = false;

    supportedFormats.forEach((item) => {
      if (item.includes(extention.toLowerCase())) {
        isCorrectDoc = true;
      }
    })

    if (!isCorrectDoc) {
      new swal({
        title: "Warning",
        icon: "Warning",
        html: `Please upload in .pdf /.doc /.docx format only`,
        type: "error",

      });
      return;
    }
    setIsSubmitting(true);

    let params = new FormData();

    params.set("FileExt", extention);
    params.set("name", name);
    params.set("email", email);
    params.set("number", number);
    params.set("employer", institution);
    params.set("position", position);
    params.set("functional_area", functionalArea);
    params.set("resume", resume);
    console.log("file", params);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post("https://chinmayavvdelhi.ac.in/api/public/index.php/career", params, config);
      new swal({
        type: "success",
        title: "Thank you! Form submitted succesfully!",
        timer: 3000,
      });
      setIsSubmitting(false);

      setTimeout(() => {
        setValues({
          name: "",
          email: "",
          number: "",
          institution: "",
          position: "",
          functionalArea: null,
          resume: null,
        });
        window.location.reload();
      }, 3000);

    } catch (e) {
      console.log(e);
      new swal({
        title: "Warning",
        html: `<div style='text-align: center'>Internal Server error</div>`,
        type: "error",
      });

      setIsSubmitting(false);
    }

  }


  return (
    <Layout>
      <SEO
        title="Careers | Chinmaya Vidyalaya Vasant Vihar"
        description="The school welcomes applications from skilled, experienced and passionate educators. Submit your professional profile or view current vacancies."
      />
      <div className="career">
        <h1 className="heading career__heading">Careers</h1>


        <p>
          Chinmaya Vidyalaya, with a novel approach to schooling, is the perfect
          institution for skilled, experienced, enthusiastic and imaginative
          educators that employ innovative, didactic approaches and tools to
          bring out the best in students.
          <br />
          <br />
          The atmosphere at Chinmaya Vidyalaya is one of constant learning and
          individual growth. Ample training, career growth opportunities and
          competitive remuneration are available for the candidates that
          showcase a flair for learning as well as teaching.
          <br />
          <br />
          We also welcome applications from experienced, skilled school
          administrators. Only shortlisted candidates are invited for personal
          interviews.
        </p>

        <div className="career__vacancies">
          <h2 className="heading">Current Vacancies</h2>

          <div className="career__vacancies__cards">
            {vacanciesData.map((item) => (
              <div className="career__vacancies__card">
                <h5>{item.vacancyType}</h5>

                <ul>
                  {item.positions.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                <h6>Criteria / Qualifications</h6>
                <p>{item.criteria}</p>
              </div>
            ))}
          </div>
        </div>

        <form onSubmit={handleFormSubmit} className="career__form">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <label>Name*</label>
              <input
                value={values.name}
                name="name"
                onChange={handleInputChange}
                placeholder="First & Last Name"
                className="career__form__input"
                required
              ></input>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Email*</label>
              <input
                name="email"
                type="email"
                onChange={handleInputChange}
                value={values.email}
                placeholder="Permanent Email Address"
                className="career__form__input"
                required
              ></input>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Contact Number*</label>
              <input
                name="number"
                onChange={handleInputChange}
                value={values.number}
                placeholder="Home or Mobile Number"
                className="career__form__input"
                required
              ></input>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Current Employer/ Last Employer*</label>
              <input
                name="institution"
                value={values.institution}
                onChange={handleInputChange}
                placeholder="Name of Institution"
                className="career__form__input"
                required
              ></input>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Application for Position*</label>
              <input
                name="position"
                value={values.position}
                onChange={handleInputChange}
                placeholder="Job/ Vacancy Title"
                className="career__form__input"
                required
              ></input>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Functional Area*</label>
              <div className="career__form__selectcontainer">
                <select
                  name="functionalArea"
                  value={values.functionalArea}
                  onChange={handleInputChange}
                  className="career__form__select"
                  required
                >
                  <option value={null}>--- Select Function Area ---</option>
                  {options.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Upload Resume*</label>
              <input
                name="resume"
                type="file"
                onChange={handleInputChange}
                placeholder="Resume"
                accept=".doc,.docx,.pdf"
                className="career__form__input"
                required
              ></input>
              <span className="career__form__uploadtext">
                Upload in PDF/ MS Word format only. Max file size is 10 MB.
              </span>
            </div>
          </div>
          <button disabled={isSubmitting} className="career__form__button">Submit</button>
        </form>
      </div>
    </Layout>
  );
}
